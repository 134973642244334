.profile__avatar-button {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  background-color: transparent;
  background-image: url(../../../images/Vector.svg);
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  opacity: 0;
  transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;
  cursor: pointer;
}

.profile__avatar-button:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.8);
}
