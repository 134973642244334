.header__logo {
  width: 142px;
  height: 33px;
  background-image: url(../../../images/logo.svg);
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 41px;
}

@media screen and (max-width: 375px) {
  .header__logo {
    width: 103px;
    height: 24px;
    margin-left: 27px;
    margin-bottom: 31px;
  }
}
