.authpage__input {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  border: none;
  border-bottom: 2px solid rgb(204, 204, 204, 0.8);
  padding-bottom: 10px;
  box-sizing: border-box;
  margin-top: 30px;
  width: 100%;
  background-color: black;
  color: rgb(204, 204, 204);
}
