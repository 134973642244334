.popup__close-button {
  padding: 0;
  border-style: none;
  width: 32px;
  height: 32px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-image: url(../../../images/Close_Icon.svg);
  position: absolute;
  top: -40px;
  right: -40px;
}

@media screen and (max-width: 618px) {
  .popup__close-button {
    background-image: url(../../../images/Close_Icon320.svg);
    width: 20px;
    height: 20px;
    top: -36px;
    right: 0;
  }
}
