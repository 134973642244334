.cards__image {
  width: 282px;
  height: 282px;
  object-fit: cover;
  display: block;
}

.cards__image:hover {
  cursor: pointer;
}
