.cards__item {
  max-width: 282px;
  background-color: white;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden;
  position: relative;
}
