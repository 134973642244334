.footer {
  max-width: 880px;
  margin: 66px auto 60px;
}

@media screen and (max-width: 375px) {
  .footer {
    margin: 50px auto 46px 19px;
  }
}
