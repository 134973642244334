.profile__avatar-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  width: 120px;
  height: 120px;
  margin-right: 30px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 522px) {
  .profile__avatar-wrapper {
    margin-right: 0;
  }
}
