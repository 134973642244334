.cards__title {
  color: black;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  max-width: 219px;
}
