.profile__add-button {
  width: 150px;
  height: 50px;
  border: 0;
  padding: 0;
  background-color: transparent;
  background-image: url(../../../images/Add_Button.svg);
  background-repeat: no-repeat;
}

@media screen and (max-width: 917px) {
  .profile__add-button {
    background-image: url(../../../images/Add_Button320.svg);
    width: 282px;
  }
}
