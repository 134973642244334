.header {
  display: flex;
  justify-content: space-between;
  max-width: 880px;
  margin: 45px auto 40px;
  border-bottom: 1px solid rgb(84, 84, 84, 0.7);
}

@media screen and (max-width: 375px) {
  .header {
    margin: 28px auto 42px;
  }
}
