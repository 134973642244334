.profile__subtitle {
  margin: 0;
  max-width: 293px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 375px) {
  .profile__subtitle {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    max-width: 189px;
  }
}
