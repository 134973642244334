.popup__submit-button {
  background-color: black;
  margin-top: 13px;
  padding: 0;
  color: white;
  border: none;
  border-radius: 2px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  width: 100%;
  height: 50px;
  cursor: pointer;
  transition: opacity 100ms ease-in-out;
}

.popup__submit-button:hover {
  opacity: 0.8;
}

@media screen and (max-width: 618px) {
  .popup__submit-button {
    font-size: 14px;
    line-height: 17px;
  }
}
