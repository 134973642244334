.profile__avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

/* .profile__avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 30px;
  object-fit: cover;
}

@media screen and (max-width: 522px) {
  .profile__avatar {
    margin-right: 0;
  }
} */
