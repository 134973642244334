.popup__container {
  margin: auto;
  background-color: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 430px;
  padding: 34px 36px 37px;
  position: relative;
  box-sizing: border-box;
}

@media screen and (max-width: 618px) {
  .popup__container {
    max-width: 282px;
    padding: 25px 22px;
  }
}
