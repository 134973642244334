.header__login_type_email {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 6px 0 0;
  text-align: right;
  margin-right: 24px;
  max-width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
