.profile__edit-button {
  border: 0;
  margin-top: 8px;
  /* margin-left: 18px; */
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url(../../../images/Edit_Button.svg);
  background-repeat: no-repeat;
  background-size: contain;
  align-self: center;
}

@media screen and (max-width: 375px) {
  .profile__edit-button {
    width: 18px;
    height: 18px;
    margin-top: 0;
  }
}
