.register__link {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding-left: 5px;
  margin: 6px 0 0;
  color: white;
  transition: opacity 100ms ease-in-out;
  text-decoration: none;
  align-self: baseline;
}

.register__link:hover {
  opacity: 0.8;
}
