.profile__info {
  max-width: 336px;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, max-content);
  column-gap: 18px;
  row-gap: 9px;
}

@media screen and (max-width: 375px) {
  .profile__info {
    padding-left: 27px;
    column-gap: 10px;
    row-gap: 7px;
  }
}
