.popup__title {
  margin: 0;
  max-width: 330px;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  color: black;
}

@media screen and (max-width: 618px) {
  .popup__title {
    font-size: 18px;
    line-height: 22px;
  }
}
