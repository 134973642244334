.cards__items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: 17px;
  row-gap: 20px;
}

@media screen and (max-width: 880px) {
  .cards__items {
    justify-content: center;
  }
}
