.profile__data {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 522px) {
  .profile__data {
    flex-direction: column;
    row-gap: 26px;
  }
}
