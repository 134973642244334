.popup__button_inactive {
  color: black;
  background: white;
  border: 1px solid black;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.2;
}
