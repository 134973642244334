.profile__title {
  margin: 0;
  max-width: 293px;
  font-weight: 500;
  font-size: 42px;
  line-height: 48px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 375px) {
  .profile__title {
    font-size: 27px;
    line-height: 33px;
    max-width: 189px;
  }
}
